import {createApp} from 'vue/dist/vue.esm-bundler.js'
import "@/assets/app.scss"
import {computed, defineAsyncComponent, ref} from "vue";

// import "@/data/navigation_json.js"
// import "@/data/logo_json.js"
// import "@/data/shop_data_json.js"
// import "@/data/commercial_banner_json.js"
// import "@/data/footer_shop_json.js"
import "@/data/catgeories_on_time.js"
// import "@/data/blog_json.js"
// import "@/data/my_json_data_to_shop.js"
// import "@/data/selector_cat_json.js"
// import "@/data/product_photos.js"
// import "@/data/projector_product_data.js"
// import "@/data/menu_settings_json.js"
// import "@/data/current_shop_settings_json.js"
// import "@/data/navigation_hotspot_products_init.js"

export const windowDimension = ref({
    width: window.innerWidth,
    height: window.innerHeight,
})
window.addEventListener('resize', () => {
    windowDimension.value.width = window.innerWidth
    windowDimension.value.height = window.innerHeight
})
export const isMobile = computed(() => {
    return windowDimension.value.width <= 978;
})

const pageName = document.querySelector('#container').classList[0]
// console.log(pageName)

// todo POCZĄTEK tylko na czas stylizacji
const shop_above_header = createApp({});
shop_above_header.component('ShopAboveHeader', defineAsyncComponent(() => import('@/components/Global/ShopInfoAboveHeader.vue')))
shop_above_header.mount('#shop_above_header')
shop_above_header.config.globalProperties.window = window;
// todo KONIEC tylko na czas stylizacji

const loading_page_checker = createApp({});
loading_page_checker.component('LoadingPageChecker', defineAsyncComponent(() => import('@/components/LoadingPageChecker.vue')))
loading_page_checker.mount('#loading_page_checker')
loading_page_checker.config.globalProperties.window = window;

const shop_header = createApp({});
shop_header.component('ShopHeader', defineAsyncComponent(() => import('@/components/ShopHeader/ShopHeader.vue')))
shop_header.mount('#shop_header')
shop_header.config.globalProperties.window = window;

const shop_header_mobile = createApp({});
shop_header_mobile.component('ShopHeader', defineAsyncComponent(() => import('@/components/ShopHeader/ShopHeaderMobile.vue')))
shop_header_mobile.mount('#shop_header_mobile')
shop_header_mobile.config.globalProperties.window = window;


const shop_info_above_header = createApp({});
shop_info_above_header.component('ShopInfoAboveHeader', defineAsyncComponent(() => import('@/components/Global/ShopInfoAboveHeader.vue')))
shop_info_above_header.mount('#shop_info_above_header')
shop_info_above_header.config.globalProperties.window = window;

const shop_footer = createApp({});
shop_footer.component('ShopFooter', defineAsyncComponent(() => import('@/components/ShopFooter/ShopFooter.vue')))
shop_footer.mount('#shop_footer')
shop_footer.config.globalProperties.window = window;

if (pageName === 'main_page' || pageName === 'projector_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page') {
    const main_page_slider_below_header = createApp({});
    main_page_slider_below_header.component('MainPageSliderBelowHeader', defineAsyncComponent(() => import('@/components/StylingElements/MainPage/MainPageSliderBelowHeader.vue')))
    main_page_slider_below_header.mount('#main_page_slider_below_header')
    main_page_slider_below_header.config.globalProperties.window = window;
}



// if (pageName === 'main_page' || pageName === 'projector_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page') {
if (pageName === 'projector_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page') {
    const shop_info_below_slider = createApp({});
    shop_info_below_slider.component('ShopInfoBelowSlider', defineAsyncComponent(() => import('@/components/StylingElements/MainPage/ShopInfoBelowSlider.vue')))
    shop_info_below_slider.mount('#shop_info_below_slider')
    shop_info_below_slider.config.globalProperties.window = window;
}

if (pageName === 'main_page' || pageName === 'projector_page' || pageName === 'basketedit_page' || pageName === 'sign_in_page' || pageName === 'order1_page' || pageName === 'return_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page' || pageName === 'login_page' || pageName === 'search_page' || pageName === 'return_page') {
    const my_hotspot_new = createApp({});
    my_hotspot_new.component('MyHotspot', defineAsyncComponent(() => import('@/components/Global/MyHotspot.vue')))
    my_hotspot_new.mount('.my_hotspot_new')
    my_hotspot_new.config.globalProperties.window = window;
}

if (pageName === 'main_page' || pageName === 'projector_page' || pageName === 'basketedit_page' || pageName === 'sign_in_page' || pageName === 'order1_page' || pageName === 'return_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page' || pageName === 'login_page' || pageName === 'search_page' || pageName === 'return_page') {
    const my_hotspot_promotion = createApp({});
    my_hotspot_promotion.component('MyHotspot', defineAsyncComponent(() => import('@/components/Global/MyHotspot.vue')))
    my_hotspot_promotion.mount('.my_hotspot_promotion')
    my_hotspot_promotion.config.globalProperties.window = window;
}

if (pageName === 'main_page' || pageName === 'projector_page' || pageName === 'basketedit_page' || pageName === 'sign_in_page' || pageName === 'order1_page' || pageName === 'return_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page' || pageName === 'login_page' || pageName === 'search_page' || pageName === 'return_page') {
    const my_hotspot_personalized = createApp({});
    my_hotspot_personalized.component('MyHotspot', defineAsyncComponent(() => import('@/components/Global/MyHotspot.vue')))
    my_hotspot_personalized.mount('.my_hotspot_personalized')
    my_hotspot_personalized.config.globalProperties.window = window;
}

if (pageName === 'main_page' || pageName === 'projector_page' || pageName === 'basketedit_page' || pageName === 'sign_in_page' || pageName === 'order1_page' || pageName === 'return_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page' || pageName === 'login_page' || pageName === 'search_page' || pageName === 'return_page') {
    const my_hotspot_zone1 = createApp({});
    my_hotspot_zone1.component('MyHotspot', defineAsyncComponent(() => import('@/components/Global/MyHotspot.vue')))
    my_hotspot_zone1.mount('.my_hotspot_zone1')
    my_hotspot_zone1.config.globalProperties.window = window;
}

if (pageName === 'main_page' || pageName === 'projector_page' || pageName === 'basketedit_page' || pageName === 'sign_in_page' || pageName === 'order1_page' || pageName === 'return_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page' || pageName === 'login_page' || pageName === 'search_page' || pageName === 'return_page') {
    const my_hotspot_zone2 = createApp({});
    my_hotspot_zone2.component('MyHotspot', defineAsyncComponent(() => import('@/components/Global/MyHotspot.vue')))
    my_hotspot_zone2.mount('.my_hotspot_zone2')
    my_hotspot_zone2.config.globalProperties.window = window;
}

if (pageName === 'main_page' || pageName === 'projector_page' || pageName === 'basketedit_page' || pageName === 'sign_in_page' || pageName === 'order1_page' || pageName === 'return_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page' || pageName === 'login_page' || pageName === 'search_page' || pageName === 'return_page') {
    const my_hotspot_zone3 = createApp({});
    my_hotspot_zone3.component('MyHotspot', defineAsyncComponent(() => import('@/components/Global/MyHotspot.vue')))
    my_hotspot_zone3.mount('.my_hotspot_zone3')
    my_hotspot_zone3.config.globalProperties.window = window;
}

if (pageName === 'main_page' || pageName === 'projector_page' || pageName === 'basketedit_page' || pageName === 'sign_in_page' || pageName === 'order1_page' || pageName === 'return_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page' || pageName === 'login_page' || pageName === 'search_page' || pageName === 'return_page') {
    const my_hotspot_zone4 = createApp({});
    my_hotspot_zone4.component('MyHotspot', defineAsyncComponent(() => import('@/components/Global/MyHotspot.vue')))
    my_hotspot_zone4.mount('.my_hotspot_zone4')
    my_hotspot_zone4.config.globalProperties.window = window;
}

// if (pageName === 'main_page') {
//     const categories_on_time = createApp({});
//     categories_on_time.component('CategoriesOnTime', defineAsyncComponent(() => import('@/components/Global/CategoriesOnTime.vue')))
//     categories_on_time.mount('#categories_on_time')
//     categories_on_time.config.globalProperties.window = window;
// }

if (pageName === 'main_page') {
    const yt_videos = createApp({});
    yt_videos.component('YtVideos', defineAsyncComponent(() => import('@/components/Global/YtVideos.vue')))
    yt_videos.mount('#yt_videos')
    yt_videos.config.globalProperties.window = window;
}

if (pageName === 'main_page') {
    const my_blog = createApp({});
    my_blog.component('MyBlog', defineAsyncComponent(() => import('@/components/Global/BlogSection.vue')))
    my_blog.mount('#my_blog')
    my_blog.config.globalProperties.window = window;
}

if (pageName === 'main_page') {
    const my_shop_producers = createApp({});
    my_shop_producers.component('ShopProducers', defineAsyncComponent(() => import('@/components/Global/ShopProducers.vue')))
    my_shop_producers.mount('#my_shop_producers')
    my_shop_producers.config.globalProperties.window = window;
}

if (pageName === 'main_page') {
    const my_what_you_get_in_shop = createApp({});
    my_what_you_get_in_shop.component('WhatYouGetInShop', defineAsyncComponent(() => import('@/components/Global/WhatYouGetInShop.vue')))
    my_what_you_get_in_shop.mount('#my_what_you_get_in_shop')
    my_what_you_get_in_shop.config.globalProperties.window = window;
}

if (pageName === 'main_page' || pageName === 'projector_page' || pageName === 'search_page' || pageName === 'basketedit_page' || pageName === 'sign_in_page' || pageName === 'order1_page' || pageName === 'order2_page' || pageName === 'return_page' || pageName === 'blog-list_page' || pageName === 'blog-item_page' || pageName === 'prepaid_page') {
    const my_newsletter = createApp({});
    my_newsletter.component('ShopNewsletter', defineAsyncComponent(() => import('@/components/Global/ShopNewsletter.vue')))
    my_newsletter.mount('#my_newsletter')
    my_newsletter.config.globalProperties.window = window;
}

if (pageName === 'main_page') {
    const trustmate_products_opinions = createApp({});
    trustmate_products_opinions.component('TrustmateProductsOpinions', defineAsyncComponent(() => import('@/components/Global/TrustmateProductsOpinions.vue')))
    trustmate_products_opinions.mount('#trustmate_products_opinions')
    trustmate_products_opinions.config.globalProperties.window = window;
}

if (pageName === 'projector_page') {
    const projector_add_product_to_basket = createApp({});
    projector_add_product_to_basket.component('ProjectorAddToBasket', defineAsyncComponent(() => import('@/components/StylingElements/ProjectorPage/ProjectorAddToBasket.vue')))
    projector_add_product_to_basket.mount('#projector_add_product_to_basket')
    projector_add_product_to_basket.config.globalProperties.window = window;
}

if (pageName === 'projector_page') {
    const projector_page_product_images = createApp({});
    projector_page_product_images.component('ProjectorPageProductImages', defineAsyncComponent(() => import('@/components/StylingElements/ProjectorPage/ProjectorPageProductImages.vue')))
    projector_page_product_images.mount('#projector_page_product_images')
    projector_page_product_images.config.globalProperties.window = window;
}

if (pageName === 'projector_page') {
    const projector_page_desc_menu = createApp({});
    projector_page_desc_menu.component('ProjectorPageDescMenu', defineAsyncComponent(() => import('@/components/StylingElements/ProjectorPage/ProjectorPageDescMenu.vue')))
    projector_page_desc_menu.mount('#projector_page_desc_menu')
    projector_page_desc_menu.config.globalProperties.window = window;
}

if (pageName === 'projector_page') {
    const new_countdown = createApp({});
    new_countdown.component('NewCountdown', defineAsyncComponent(() => import('@/components/StylingElements/ProjectorPage/NewCountdown.vue')))
    new_countdown.mount('#new_countdown')
    new_countdown.config.globalProperties.window = window;
}

const styling_section = createApp({});
styling_section.component('StylingSection', defineAsyncComponent(() => import('@/components/StylingElements/StylingSection.vue')))
styling_section.mount('#styling_section')
styling_section.config.globalProperties.window = window;


// przykład implementacji
// const app2 = createApp({});
// app2.component('ShopNavigation2',defineAsyncComponent(()=>import('@/components/ShopNavigation2.vue')))
// app2.mount('#app2')
// app2.config.globalProperties.window=window

// container w xsl
// <div id="app2">
// <iaixsl:text disable-output-escaping="yes">
// &lt;shop-navigation2 /&gt;
// </iaixsl:text>
// </div>
