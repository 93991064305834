if (window.location.host === "localhost:5173") {
    window.categories_on_time_json = {
        title: 'Kategorie na czasie',
        categories: [
            {
                link: 'https://4kom.pl/pl/menu/torby-i-plecaki/plecaki-do-samolotu-plecaki-podrozne-torby-plecaki-meskie-na-laptopa-44726.html/',
                thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/wygodne_plecaki.png'
            },
            {
                link: 'https://4kom.pl/pl/menu/do-smartwatchy-22531.html',
                thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/akcesoria_do_smartwatchy.png'
            },
            {
                link: 'https://4kom.pl/pl/menu/motoryzacja/uchwyty-samochodowe-44649.html',
                thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/uchwyty_do_auta.png'
            },
            {
                link: 'https://4kom.pl/pl/menu/sport-i-turystyka/akcesoria-treningowe-44684.html',
                thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/akcesoria_fitness.png'
            },
            {
                link: 'https://4kom.pl/pl/bestsellers/bestseller.html',
                thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/bestsellery.png'
            },
            {
                link: 'https://4kom.pl/pl/menu/elektronika-44665.html',
                thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/kable_i_ladowarki.png'
            },
        ],
    }
}

var categories_on_time_json = {
    title: 'Kategorie na czasie',
    categories: [
        {
            link: 'https://4kom.pl/pl/menu/torby-i-plecaki/plecaki-44726.html',
            thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/wygodne_plecaki.png'
        },
        {
            link: 'https://4kom.pl/pl/menu/torby-i-plecaki/plecaki-44726.html',
            thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/akcesoria_do_smartwatchy.png'
        },
        {
            link: 'https://4kom.pl/pl/menu/torby-i-plecaki/plecaki-44726.html',
            thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/uchwyty_do_auta.png'
        },
        {
            link: 'https://4kom.pl/pl/menu/torby-i-plecaki/plecaki-44726.html',
            thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/akcesoria_fitness.png'
        },
        {
            link: 'https://4kom.pl/pl/menu/torby-i-plecaki/plecaki-44726.html',
            thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/bestsellery.png'
        },
        {
            link: 'https://4kom.pl/pl/menu/torby-i-plecaki/plecaki-44726.html',
            thumbnail:  'https://4kom.pl/data/include/cms/4KOM_UNIWERSALNY_12.2024/img/categories_on_time/kable_i_ladowarki.png'
        },
    ],
}